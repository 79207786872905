import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class CashInService {
    readCashInQuery(){
        var query = `query($paging:ServerPaging, $type:Int!, $status:String, $region:Int, $month:Int, $year:Int){
                        GetCashMutation(paging:$paging, type:$type, status:$status, regionId:$region, month:$month, year:$year){
                            cash_mutation{
                                id
                                status
                                region_id
                                region_name
                                transaction_date
                                transaction_number
                                balance
                                month_year
                                balance_month
                                year
                                balance_year
                                contact_id
                                contact_name
                                chart_of_account_id
                                chart_of_account_name
                                amount
                                foreign_currency
                                region_balance
                                region
                                notes
                            }
                            total
                        }
                    }`;
        return query;
    }

    async getDetailCashIn(id){
        const variables = {
            id : id,
            type : 3
        }
        var query = gql`query($id:Int, $type:Int!){
            GetCashMutation(ID: $id, type:$type){
                cash_mutation{
                    id
                    status
                    region_id
                    region_name
                    transaction_date
                    balance
                    month_year
                    balance_month
                    year
                    balance_year
                    region
                    region_balance
                    transaction_number
                    contact_id
                    contact_name
                    type
                    desc
                    chart_of_account_id
                    chart_of_account_name
                    currency
                    amount
                    exchange_rate
                    foreign_currency
                    notes
                    approve_by_finance
                    approve_by_finance_name
                    approve_by_accounting
                    approve_by_accounting_name
                    cash_mutation_detail{
                        chart_of_account_id
                        chart_of_account_name
                        currency
                        amount
                        exchange_rate
                        foreign_currency
                        division
                        notes
                        budgeting
                        sub_project
                    }
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetCashMutation.cash_mutation[0];
    }

    async getRegionQuery(){
        var query = gql`query{
            GetRoleRegions{
                region_id
                region_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetRoleRegions;
        
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value:data[i].region_id, 
                            label:data[i].region_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getContactQuery(){
        var query = gql`query{
            GetContact{
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCOAQuery(){
        var query = gql`query{
            GetChartOfAccountingIds{
                id
                name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetChartOfAccountingIds != null){
            for (let i = 0; i < result.data.GetChartOfAccountingIds.length; i++) {
                var str = { value:result.data.GetChartOfAccountingIds[i].id, 
                            label:result.data.GetChartOfAccountingIds[i].name + ' (' + result.data.GetChartOfAccountingIds[i].id + ')'}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCurrency(){
        var query = gql`query{
            GetCurrency{
                currency_code
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetCurrency != null){
            for (let i = 0; i < result.data.GetCurrency.length; i++) {
                var str = { value:result.data.GetCurrency[i].currency_code, 
                            label:result.data.GetCurrency[i].currency_code}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async addCashIn(variables){
        var query = gql`
            mutation ($data:NewCashMutation!) {
                CreateCashInMutation (new:$data){
                    data
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editCashIn(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewCashMutation!) {
                UpdateCashInMutation (Id:$id,new:$data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
                DeleteJournal(Id:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { seq : i + 1,
                            chart_of_account_id : data[i].chart_of_account_id,
                            notes : data[i].notes,
                            amount : kendo.toString(data[i].amount, "n2")}
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    getAllTotal(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].amount; 
            }
        }
        return totalPrice;
    }
}

export default new CashInService();