import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class AdjustmentJournalService {
    readAdjustmentJournalQuery(){
        var query = `
            query ($paging:ServerPaging, $type:Int!, $status:String, $region:Int, $month:Int, $year:Int) {
                GetJournal (paging:$paging, type:$type, status:$status, regionId:$region, month:$month, year:$year) {
                    journal {
                        id
                        status
                        region_id
                        region_name
                        transaction_date
                        balance
                        month_year
                        balance_month
                        year
                        balance_year
                        region
                        region_balance
                        transaction_number
                        contact_id
                        contact_name
                        type
                        desc
                        notes
                        approve_by_finance
                        approve_by_finance_name
                        approve_by_accounting
                        approve_by_accounting_name
                        created_at
                        last_update
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getDetailJournal(id){
        const variables = {
            id : id,
            type : 2
        }
        var query = gql`
            query($id:Int, $type:Int!){
                GetJournal (ID:$id, type:$type) {
                    journal {
                        id
                        status
                        region_id
                        region_name
                        transaction_date
                        balance
                        month_year
                        balance_month
                        year
                        balance_year
                        region
                        region_balance
                        transaction_number
                        contact_id
                        contact_name
                        type
                        desc
                        notes
                        approve_by_finance
                        approve_by_finance_name
                        approve_by_accounting
                        approve_by_accounting_name
                        journal_detail {
                            id
                            chart_of_account_id
                            chart_of_account_name
                            currency
                            debit
                            credit
                            exchange_rate
                            foreign_currency_debit
                            foreign_currency_credit
                            division
                            notes
                            budgeting
                            sub_project
                        }
                        created_at
                        last_update
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetJournal.journal[0];
    }

    async getRegionQuery(){
        var query = gql`query{
            GetRoleRegions{
                region_id
                region_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetRoleRegions;
        
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value:data[i].region_id, 
                            label:data[i].region_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }    

    async getContactQuery(){
        var query = gql`query{
            GetContact{
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCOAQuery(){
        var query = gql`query{
            GetChartOfAccountingIds{
                id
                name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetChartOfAccountingIds != null){
            for (let i = 0; i < result.data.GetChartOfAccountingIds.length; i++) {
                var str = { value:result.data.GetChartOfAccountingIds[i].id, 
                            label:result.data.GetChartOfAccountingIds[i].name + ' (' + result.data.GetChartOfAccountingIds[i].id + ')'}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCurrency(){
        var query = gql`query{
            GetCurrency{
                currency_code
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetCurrency != null){
            for (let i = 0; i < result.data.GetCurrency.length; i++) {
                var str = { value:result.data.GetCurrency[i].currency_code, 
                            label:result.data.GetCurrency[i].currency_code}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async addJournal(variables){
        var query = gql`
            mutation ($data: NewJournal!) {
                CreateJournalAdjustment (new: $data){
                    data
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editJournal(variables){
        var query = gql`
            mutation ($id: Int!, $data: NewJournal!) {
                UpdateJournalAdjustment (Id: $id, new: $data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
                DeleteJournal(Id:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { seq : i + 1,
                            chart_of_account_id : data[i].chart_of_account_id,
                            notes : data[i].notes,
                            debit_amount : kendo.toString(data[i].debit, "n2"),
                            credit_amount : kendo.toString(data[i].credit, "n2")}
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    getAllTotalDebit(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].debit; 
            }
        }
        return totalPrice;
    }

    getAllTotalCredit(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].credit; 
            }
        }
        return totalPrice;
    }
}

export default new AdjustmentJournalService();