import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class GeneralJournalService {
    readGeneralJournalQuery(){
        var query = `
            query ($paging:ServerPaging, $type:Int!, $status:String, $region:Int, $month:Int, $year:Int) {
                GetJournal (paging:$paging, type:$type, status:$status, regionId:$region, month:$month, year:$year) {
                    journal {
                        id
                        status
                        region_id
                        region_name
                        transaction_date
                        balance
                        month_year
                        balance_month
                        year
                        balance_year
                        region
                        region_balance
                        transaction_number
                        contact_id
                        contact_name
                        type
                        desc
                        notes
                        approve_by_finance
                        approve_by_finance_name
                        approve_by_accounting
                        approve_by_accounting_name
                        created_at
                        last_update
                    }
                    total
                }
            }
        `;
        return query;
    }

    getJournalableQuery(){
        var query = ` query($type:Int!, $hidden:Boolean){
            GetJournalable(type:$type, hidden:$hidden){
                inv_payment_id
                inv_payment_number
                contact_id
                contact_name
                inv_payment_date
                payment_method
                bank
                account_number
                account_name
                round_up
                delivery_fee
                admin_fee
                other_fee
                currency_id
                currency_name
                currency_code
                total
                hide_journalable
            }
        }`;
        return query;
    }

    async getDetailJournal(id){
        const variables = {
            id : id,
            type : 1
        }
        var query = gql`
            query($id:Int, $type:Int!){
                GetJournal (ID:$id, type:$type) {
                    journal {
                        id
                        status
                        region_id
                        region_name
                        transaction_date
                        balance
                        month_year
                        balance_month
                        year
                        balance_year
                        region
                        region_balance
                        transaction_number
                        contact_id
                        contact_name
                        type
                        desc
                        notes
                        approve_by_finance
                        approve_by_finance_name
                        approve_by_accounting
                        approve_by_accounting_name
                        journal_detail {
                            id
                            chart_of_account_id
                            chart_of_account_name
                            currency
                            debit
                            credit
                            exchange_rate
                            foreign_currency_debit
                            foreign_currency_credit
                            division
                            notes
                            budgeting
                            sub_project
                        }
                        created_at
                        last_update
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetJournal.journal[0];
    }

    async getRegionQuery(){
        var query = gql`query{
            GetRoleRegions{
                region_id
                region_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetRoleRegions;
        
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value:data[i].region_id, 
                            label:data[i].region_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async getContactQuery(){
        var query = gql`query{
            GetContact{
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCOAQuery(){
        var query = gql`query{
            GetChartOfAccountingIds{
                id
                name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetChartOfAccountingIds != null){
            for (let i = 0; i < result.data.GetChartOfAccountingIds.length; i++) {
                var str = { value:result.data.GetChartOfAccountingIds[i].id, 
                            label:result.data.GetChartOfAccountingIds[i].name + ' (' + result.data.GetChartOfAccountingIds[i].id + ')'}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCurrency(){
        var query = gql`query{
            GetCurrency{
                currency_code
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetCurrency != null){
            for (let i = 0; i < result.data.GetCurrency.length; i++) {
                var str = { value:result.data.GetCurrency[i].currency_code, 
                            label:result.data.GetCurrency[i].currency_code}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async addJournal(variables){
        var query = gql`
            mutation ($data: NewJournal!) {
                CreateJournal (new: $data){
                    data
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editJournal(variables){
        var query = gql`
            mutation ($id: Int!, $data: NewJournal!) {
                UpdateJournal (Id: $id, new: $data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
                DeleteJournal(Id:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { seq : i + 1,
                            chart_of_account_id : data[i].chart_of_account_id,
                            notes : data[i].notes,
                            debit_amount : kendo.toString(data[i].debit, "n2"),
                            credit_amount : kendo.toString(data[i].credit, "n2")}
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    getAllTotalDebit(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].debit; 
            }
        }
        return totalPrice;
    }

    getAllTotalCredit(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].credit; 
            }
        }
        return totalPrice;
    }

    async getPurchasingInvoicePaymentQuery(id){
        const variables = {
            id : id
        }
        var query = gql`
            query($id:Int){
                GetPurchasingInvoicePaymentV(InvPaymentId:$id){
                    purchasing_invoice_payment {
                        created_at
                        last_update
                        inv_payment_id
                        inv_payment_number
                        inv_payment_type
                        inv_payment_date
                        status
                        supplier_id
                        supplier_name
                        payment_method
                        bank
                        branch
                        account_number
                        account_name
                        round_up
                        delivery_fee
                        admin_fee
                        other_fee
                        notes
                        currency_id
                        currency_name
                        currency_code
                        total
                        purchasing_invoice_payment_detail {
                          created_at
                          last_update
                          inv_payment_id
                          invoice_id
                          invoice_number
                          invoice_date
                          tax_invoice
                          total
                          paid
                          total_payment
                          left
                          notes
                        }
                    }
                }
            }`
        ;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    
        return result.data.GetPurchasingInvoicePaymentV.purchasing_invoice_payment[0];
    }

    setPurchasingInvoicePaymentData(data){
        var detailData = data.purchasing_invoice_payment_detail;
        var arrayObject = [];
        if(detailData.length != 0){
            for (let i = 0; i < detailData.length; i++){
                var foreignCurrency = 1 * detailData[i].total_payment;
                var InvDate = kendo.toString(kendo.parseDate(new Date(detailData[i].invoice_date).toISOString(), 'yyyy-MM-dd'), 'dd/MM/yyyy');
                var notes = data.supplier_name + "\n" + detailData[i].tax_invoice + "\n" + InvDate; 
                var row = { 
                    chart_of_account_id: "211002",
                    chart_of_account_name: "HUTANG DAGANG LOKAL",
                    currency: "IDR",
                    debit: detailData[i].total_payment,
                    credit: 0,
                    exchange_rate: 1,
                    foreign_currency_debit: foreignCurrency,
                    foreign_currency_credit: 0,
                    division: "-",
                    notes: notes,
                    budgeting: "-",
                    sub_project: "",
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    async editJournalable(variables){
        var query = gql`
            mutation ($invPaymentID: Int!, $type: Int!, $hidden: Boolean) {
                UpdateJournalable (InvPaymentID: $invPaymentID, type: $type, hidden: $hidden)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new GeneralJournalService();