import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class BankOutService {
    readBankOutQuery(){
        var query = `query($paging:ServerPaging, $type:Int!, $status:String, $region:Int, $month:Int, $year:Int){
                        GetBankMutation(paging:$paging, type:$type, status:$status, regionId:$region, month:$month, year:$year){
                            bank_mutation{
                                id
                                status
                                region_id
                                region_name
                                transaction_date
                                transaction_number
                                balance
                                month_year
                                balance_month
                                year
                                balance_year
                                contact_id
                                contact_name
                                chart_of_account_id
                                chart_of_account_name
                                amount
                                foreign_currency
                                region_balance
                                region
                                notes
                            }
                            total
                        }
                    }`;
        return query;
    }

    getJournalableQuery(){
        var query = ` query($type:Int!, $hidden:Boolean){
            GetJournalable(type:$type, hidden:$hidden){
                inv_payment_id
                inv_payment_number
                contact_id
                contact_name
                inv_payment_date
                payment_method
                bank
                account_number
                account_name
                round_up
                delivery_fee
                admin_fee
                other_fee
                currency_id
                currency_name
                currency_code
                total
                hide_journalable
            }
        }`;
        return query;
    }

    async getInvoicePaymentQuery(id){
        const variables = {
            id : id
        }
        var query = gql`
            query($id:Int){
                GetPurchasingInvoicePaymentV(InvPaymentId:$id){
                    purchasing_invoice_payment {
                        created_at
                        last_update
                        inv_payment_id
                        inv_payment_number
                        inv_payment_type
                        inv_payment_date
                        status
                        supplier_id
                        supplier_name
                        payment_method
                        bank
                        branch
                        account_number
                        account_name
                        round_up
                        delivery_fee
                        admin_fee
                        other_fee
                        notes
                        currency_id
                        currency_name
                        currency_code
                        total
                        purchasing_invoice_payment_detail {
                          created_at
                          last_update
                          inv_payment_id
                          invoice_id
                          invoice_number
                          invoice_date
                          tax_invoice
                          total
                          paid
                          total_payment
                          left
                          notes
                        }
                    }
                }
            }`
        ;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    
        return result.data.GetPurchasingInvoicePaymentV.purchasing_invoice_payment[0];
    }

    async getDetailBankOut(id){
        const variables = {
            id : id,
            type : 6
        }
        var query = gql`query($id:Int, $type:Int!){
            GetBankMutation(ID: $id, type:$type){
                bank_mutation{
                    id
                    status
                    region_id
                    region_name
                    transaction_date
                    balance
                    month_year
                    balance_month
                    year
                    balance_year
                    region
                    region_balance
                    transaction_number
                    contact_id
                    contact_name
                    type
                    desc
                    chart_of_account_id
                    chart_of_account_name
                    currency
                    amount
                    exchange_rate
                    foreign_currency
                    notes
                    approve_by_finance
                    approve_by_finance_name
                    approve_by_accounting
                    approve_by_accounting_name
                    bank_mutation_detail{
                        chart_of_account_id
                        chart_of_account_name
                        currency
                        amount
                        exchange_rate
                        foreign_currency
                        division
                        notes
                        budgeting
                        sub_project
                    }
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetBankMutation.bank_mutation[0];
    }

    async getRegionQuery(){
        var query = gql`query{
            GetRoleRegions{
                region_id
                region_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetRoleRegions;
        
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value:data[i].region_id, 
                            label:data[i].region_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getContactQuery(){
        var query = gql`query{
            GetContact{
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCOAQuery(){
        var query = gql`query{
            GetChartOfAccountingIds{
                id
                name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetChartOfAccountingIds != null){
            for (let i = 0; i < result.data.GetChartOfAccountingIds.length; i++) {
                var str = { value:result.data.GetChartOfAccountingIds[i].id, 
                            label:result.data.GetChartOfAccountingIds[i].name + ' (' + result.data.GetChartOfAccountingIds[i].id + ')'}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getCurrency(){
        var query = gql`query{
            GetCurrency{
                currency_code
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetCurrency != null){
            for (let i = 0; i < result.data.GetCurrency.length; i++) {
                var str = { value:result.data.GetCurrency[i].currency_code, 
                            label:result.data.GetCurrency[i].currency_code}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async addBankOut(variables){
        var query = gql`
            mutation ($data:NewBankMutation!) {
                CreateBankOutMutation (new:$data){
                    data
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editBankOut(variables){
        var query = gql`
            mutation ($id:Int!,$data:NewBankMutation!) {
                UpdateBankOutMutation (Id:$id,new:$data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
                DeleteJournal(Id:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { seq : i + 1,
                            chart_of_account_id : data[i].chart_of_account_id,
                            notes : data[i].notes,
                            amount : kendo.toString(data[i].amount, "n2")}
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    getAllTotal(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].amount; 
            }
        }
        return totalPrice;
    }

    setInvoicePaymentData(data){
        var detailData = data.purchasing_invoice_payment_detail;
        var arrayObject = [];
        if(detailData.length != 0){
            for (let i = 0; i < detailData.length; i++){
                var foreignCurrency = 1 * detailData[i].total_payment;
                var InvDate = kendo.toString(kendo.parseDate(new Date(detailData[i].invoice_date).toISOString(), 'yyyy-MM-dd'), 'dd/MM/yyyy');
                var notes = data.supplier_name + " \n" + detailData[i].tax_invoice + " \n" + InvDate; 
                var row = { 
                    chart_of_account_id: "211002",
                    chart_of_account_name: "HUTANG DAGANG LOKAL",
                    currency: "IDR",
                    amount: detailData[i].total_payment,
                    exchange_rate: 1,
                    foreign_currency: foreignCurrency,
                    division: "-",
                    notes: notes,
                    budgeting: "-",
                    sub_project: "",
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    async editJournalable(variables){
        var query = gql`
            mutation ($invPaymentID: Int!, $type: Int!, $hidden: Boolean) {
                UpdateJournalable (InvPaymentID: $invPaymentID, type: $type, hidden: $hidden)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new BankOutService();